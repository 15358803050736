import React, { createContext, ReactNode, useContext, useState } from 'react';

export const ImpressaoPedidoVendaContext = createContext(
  {} as ImpressaoPedidoVendaContextData,
);

type ImpressaoPedidoVendaContextData = {
  isLoading: boolean;
};

interface ImpressaoPedidoVendaContextProviderProps {
  children: ReactNode;
}

export function ImpressaoPedidoVendaContextProvider({
  children,
}: ImpressaoPedidoVendaContextProviderProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ImpressaoPedidoVendaContext.Provider value={{ isLoading }}>
      {children}
    </ImpressaoPedidoVendaContext.Provider>
  );
}

export const useImpressaoPedidoVenda = (): ImpressaoPedidoVendaContextData => {
  return useContext(ImpressaoPedidoVendaContext);
};
