import styled from 'styled-components';

export const ActionsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 52px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;

  .btn {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    .btn-icon {
      margin-left: 0.5rem;
    }
  }
  .btn-impressao {
    background-color: #2d136c;
    border-color: #200e49;
    &:focus {
      box-shadow: 0 0 0 0.25rem #c2a0f8;
    }
  }

  .btn-excel {
    background-color: #0e6335;
    border-color: #0b4928;
    &:focus {
      box-shadow: 0 0 0 0.25rem #63a586;
    }
  }

  .btn-pdf {
    background-color: #ad0b00;
    border-color: #7c0901;
    &:focus {
      box-shadow: 0 0 0 0.25rem #c16f76;
    }
  }
`;
