import styled from 'styled-components';

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0px 52px 16px 0px;
`;

export const SelectContainer = styled.div`
  width: 100%;
  .view-select {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  align-self: end;
  gap: 0.5rem !important;
`;
