import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Option, FormView, View } from './types';
import { OptionsProps } from '../Visualizacao/types';

type ImpressaoContextData = {
  optionsAvailable: OptionsProps[];
  setOptionsAvailable: (val: OptionsProps[]) => void;
  selected: OptionsProps[];
  setSelected: (val: OptionsProps[]) => void;
  changeSelected: (items: OptionsProps[]) => void;
  changeSelectedView: (items: OptionsProps[]) => void;
  codTela: number;
  handleCodTela: (tela: number) => void;
  filters: any[];
  handleFilters: (newFilters: any[]) => void;
  isChangeView: boolean;
  handleIsChangeView: (value: boolean) => void;
  hasSelectedView: boolean;
  handleHasSelectedView: (value: boolean) => void;
  handleSaveView: () => void;
  formView: FormView;
  handleChangeFormView: (value: FormView) => void;
  isNewView: boolean;
  handleIsNewView: (value: boolean) => void;
  selectedView: View;
  handleSelectedView: (value: View) => void;
  getViews: () => void;
  selectedOption: Option | null;
  handleSelectedOption: (value: Option | null) => void;
  views: View[];
  optionsSelect: Option[];
  handleOptionsSelect: (value: Option[]) => void;
  isLoading: boolean;
  handleChangeIsLoading: (value: boolean) => void;

  optionsAvailableDefault: OptionsProps[];
  setOptionsAvailableDefault: (val: OptionsProps[]) => void;
};

export const ImpressaoContext = createContext({} as ImpressaoContextData);

interface ImpressaoContextProviderProps {
  children: ReactNode;
}

export function ImpressaoContextProvider({
  children,
}: ImpressaoContextProviderProps): JSX.Element {
  const [optionsAvailable, setOptionsAvailable] = useState<OptionsProps[]>([]);
  const [optionsAvailableDefault, setOptionsAvailableDefault] = useState<
    OptionsProps[]
  >([]);
  const [selected, setSelected] = useState<OptionsProps[]>([]);
  const [codTela, setCodTela] = useState<number>(0);
  const [filters, setFilters] = useState<any[]>([]);
  const [isChangeView, setIsChangeView] = useState(false);
  const [hasSelectedView, setHasSelectedView] = useState(false);
  const [formView, setFormView] = useState({} as FormView);
  const [isNewView, setIsNewView] = useState(false);
  const [selectedView, setSelectedView] = useState<View>({} as View);
  const [views, setViews] = useState<View[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [optionsSelect, setOptionsSelect] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeIsLoading = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const handleOptionsSelect = useCallback((value: Option[]) => {
    setOptionsSelect(value);
  }, []);

  const handleSelectedOption = (value: Option | null) => {
    setSelectedOption(value);
  };

  const getViews = useCallback(async () => {
    try {
      const res = await api.get(`/impressao/view/${codTela}`);
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);

      setViews(data);

      let viewOptions = data.map((item: View) => {
        return {
          label: item.des_impressao,
          value: item.cod_impressao,
        };
      });

      viewOptions = [...viewOptions];

      setOptionsSelect(viewOptions);
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [codTela]);

  useEffect(() => {
    getViews();
  }, [getViews]);

  const handleSelectedView = useCallback((value: View) => {
    setSelectedView(value);
  }, []);

  const handleIsNewView = useCallback((value: boolean) => {
    setIsNewView(value);
  }, []);

  useEffect(() => {
    if (codTela) {
      getFields(codTela);
    }
  }, [codTela]);

  const handleChangeFormView = useCallback((value: FormView) => {
    setFormView(value);
  }, []);

  const getFields = async (tela: number) => {
    setIsLoading(true);
    try {
      const res = await api.get(`/impressao/campos/${tela}`);
      const { success, fields, message } = res.data;
      if (!success) throw new Error(message);

      const optionField = fields.options.map((field: any) => ({
        order: 1,
        cod_campo: field.value,
        des_campo: field.label,
      }));

      setOptionsAvailable(optionField);
      setOptionsAvailableDefault(optionField);
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCodTela = (tela: number) => {
    setCodTela(tela);
  };

  const handleFilters = (newFilters: any[]) => {
    setFilters(newFilters);
  };

  const handleHasSelectedView = (value: boolean) => {
    setHasSelectedView(value);
  };

  const changeSelectedView = (items: OptionsProps[]) => {
    setSelected(items);
    setIsChangeView(false);
  };

  const changeSelected = (items: any[]) => {
    setSelected(items);
    if (hasSelectedView) {
      setIsChangeView(true);
    }
  };

  const handleIsChangeView = (value: boolean) => {
    setIsChangeView(value);
  };

  const handleSaveView = async () => {
    try {
      const form = formView;

      if (selected.length < 1) {
        return toast.warning(
          'Inclua ao menos um campo para a criação da visualização',
        );
      }

      const selectedFilterArray = [];

      for (let index = 0; index < selected.length; index++) {
        selectedFilterArray.push(selected[index].cod_campo);
      }

      form.cod_tela = codTela;
      form.selected_filters = selectedFilterArray;

      if (!isNewView) {
        const res = await api.put(
          `/impressao/view/${selectedView?.cod_impressao}`,
          form,
        );
        const { success, message } = res.data;
        if (!success) throw new Error(message);
        handleIsChangeView(false);
        getViews();
        return toast.success(message);
      }

      const res = await api.post('/impressao/view', form);
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      setSelectedView(data);
      handleIsChangeView(false);
      setIsNewView(false);
      getViews();
      toast.success(message);
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <ImpressaoContext.Provider
      value={{
        selected,
        changeSelected,
        changeSelectedView,
        codTela,
        handleCodTela,
        filters,
        handleFilters,
        isChangeView,
        handleIsChangeView,
        hasSelectedView,
        handleHasSelectedView,
        handleSaveView,
        formView,
        handleChangeFormView,
        isNewView,
        handleIsNewView,
        selectedView,
        handleSelectedView,
        getViews,
        selectedOption,
        handleSelectedOption,
        views,
        optionsSelect,
        handleOptionsSelect,
        isLoading,
        handleChangeIsLoading,
        setSelected,
        optionsAvailable,
        setOptionsAvailable,

        optionsAvailableDefault,
        setOptionsAvailableDefault,
      }}
    >
      {children}
    </ImpressaoContext.Provider>
  );
}

export const useImpressao = (): ImpressaoContextData => {
  return useContext(ImpressaoContext);
};
