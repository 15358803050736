import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlinePrinter,
} from 'react-icons/ai';
import { toast } from 'react-toastify';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';

import { useImpressao } from '../../ImpressaoContext';
import { ActionsContainer, ButtonsContainer, OptionsContainer } from './styles';

import 'react-dual-listbox/lib/react-dual-listbox.css';

type ParamsPDFReport = {
  headerIds: number[];
  codTela: number;
  showFilters: boolean;
  filters: any[] | undefined;
  showStriped: boolean;
  showBottomBorder: boolean;
};

export const ActionsComponent: React.FC = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showStriped, setShowStriped] = useState(false);
  const [showBottomBorder, setShowBottomBorder] = useState(false);

  const { selected, codTela, filters, handleChangeIsLoading } = useImpressao();

  const getPDFReport = async (params: ParamsPDFReport) => {
    const res = await api.get('/impressao/report/pdf', {
      params,
    });

    const { success, pdf, message, screenName } = res.data;
    if (!success) throw new Error(message);
    if (message.length > 0) toast.warning(message);

    return { pdf, screenName };
  };

  const convertBase64ToBlob = (base64String: string): any => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  };

  const handleGeneratePDF = async () => {
    try {
      if (selected.length < 1) {
        return toast.warning(
          'Inclua ao menos um campo para a geração do relatório',
        );
      }
      handleChangeIsLoading(true);
      const selectedFilterArray = [];

      for (let index = 0; index < selected.length; index++) {
        selectedFilterArray.push(selected[index].cod_campo);
      }

      const { pdf, screenName } = await getPDFReport({
        headerIds: selectedFilterArray,
        codTela,
        showFilters,
        filters,
        showStriped,
        showBottomBorder,
      });

      const byteArray = convertBase64ToBlob(pdf);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const year = moment().format('YYYY');
      const month = moment().format('MM');
      const day = moment().format('DD');

      saveAs(
        blob,
        `${screenName.replaceAll(' ', '_')}_${year}_${month}_${day}`,
      );
    } catch (e) {
      return toast.error((e as Error).message);
    } finally {
      handleChangeIsLoading(false);
    }
  };

  const handlePrinter = async () => {
    try {
      if (selected.length < 1) {
        return toast.warning(
          'Inclua ao menos um campo para a geração do relatório',
        );
      }
      handleChangeIsLoading(true);

      const selectedFilterArray = [];

      for (let index = 0; index < selected.length; index++) {
        selectedFilterArray.push(selected[index].cod_campo);
      }

      const { pdf, screenName } = await getPDFReport({
        headerIds: selectedFilterArray,
        codTela,
        showFilters,
        filters,
        showStriped,
        showBottomBorder,
      });

      if (pdf !== null) {
        const byteArray = convertBase64ToBlob(pdf);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(blob);
        const windowReference = window.open(fileURL, 'height=500,width=500');
        if (!windowReference) {
          toast.warning('O pop-up de impressão foi bloqueado pelo navegador.');
        }
      } else {
        toast.warning('Nenhum PDF foi retornado pela API.');
      }
      handleChangeIsLoading(false);
    } catch (e) {
      handleChangeIsLoading(false);
      return toast.error((e as Error).message);
    }
  };

  const handleGenerateExcel = async () => {
    try {
      handleChangeIsLoading(true);
      if (selected.length < 1) {
        return toast.warning(
          'Inclua ao menos um campo para a geração do relatório',
        );
      }

      const selectedFilterArray = [];

      for (let index = 0; index < selected.length; index++) {
        selectedFilterArray.push(selected[index].cod_campo);
      }

      const res = await api.get('/impressao/report/xlsx', {
        params: {
          headerIds: selectedFilterArray,
          codTela,
          showFilters,
          filters,
        },
      });
      const { success, file, message, screenName } = res.data;
      if (!success) throw new Error(message);
      const byteArray = convertBase64ToBlob(file);
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const year = moment().format('YYYY');
      const month = moment().format('MM');
      const day = moment().format('DD');

      saveAs(
        blob,
        `${screenName.replaceAll(' ', '_')}_${year}_${month}_${day}`,
      );
    } catch (e) {
      toast.error((e as Error).message);
    } finally {
      handleChangeIsLoading(false);
    }
  };

  return (
    <ActionsContainer className="mt-2">
      <OptionsContainer>
        <ToggleDefault
          labelText="Exibir Filtros"
          setChecked={showFilters}
          onSwitch={() => setShowFilters(!showFilters)}
        />
        <ToggleDefault
          labelText="Tabela Zebrada"
          setChecked={showStriped}
          onSwitch={() => setShowStriped(!showStriped)}
        />
        <ToggleDefault
          labelText="Borda Inferior"
          setChecked={showBottomBorder}
          onSwitch={() => setShowBottomBorder(!showBottomBorder)}
        />
      </OptionsContainer>

      <ButtonsContainer>
        <Button
          className="btn-pdf"
          onClick={handleGeneratePDF}
          title="Exportar para PDF"
        >
          PDF
          <AiOutlineFilePdf size={24} className="btn-icon" />
        </Button>

        <Button
          className="btn-excel"
          onClick={handleGenerateExcel}
          title="Exportar para Excel"
        >
          Excel
          <AiOutlineFileExcel size={24} className="btn-icon" />
        </Button>

        <Button
          className="btn-impressao"
          onClick={handlePrinter}
          title="Imprimir em tela"
        >
          Imprimir
          <AiOutlinePrinter size={24} className="btn-icon" />
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  );
};
