import React, { useEffect, useState } from 'react';

import { CloseButton, Option, Container, ContainerOptions } from './styles';

import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';

import { FaPrint } from 'react-icons/fa';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Typography } from '@material-ui/core';
import { useImpressaoPedidoVenda } from './ImpressaoPedidoVendaContext';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

interface IImpressaoPedidoVenda {
  show: boolean;
  handleShow: (show: boolean) => void;
  handlePrint: (option: string | undefined) => void;
}

const MySwal = withReactContent(Swal);

const ImpressaoPedidoVenda: React.FC<IImpressaoPedidoVenda> = ({
  show,
  handleShow,
  handlePrint,
}: IImpressaoPedidoVenda) => {
  const { isLoading } = useImpressaoPedidoVenda();
  const [selectedOption, setSelectedOption] = useState(undefined);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
  };

  const handleConfirm = (option: string) => {
    if (option) {
      handlePrint(option);
      handleShow(false);
      return;
    }
    toast.warn('Selecione pelo menos um tipo de impressão.');
  };

  const handleCloseModal = () => {
    handleShow(false);
  };

  return (
    <>
      {show && (
        <Modal onClose={handleCloseModal}>
          <ModalHeader className="d-flex justify-content-between">
            <ModalTitle>Escolha o modelo de impressão</ModalTitle>
            <CloseButton onClick={handleCloseModal}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Container>
              <ContainerOptions>
                <Option
                  isSelected={selectedOption === 'A4'}
                  onClick={() => handleConfirm('A4')}
                >
                  <FaPrint
                    style={{ fontSize: 36, color: '#9C60FF', marginBottom: 10 }}
                  />
                  <Typography>Sulfite A4</Typography>
                </Option>
                <Option
                  isSelected={selectedOption === 'bobina'}
                  onClick={() => handleConfirm('bobina')}
                >
                  <FaPrint
                    style={{ fontSize: 36, color: '#9C60FF', marginBottom: 10 }}
                  />
                  <Typography>Bobina contínua</Typography>
                </Option>
              </ContainerOptions>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ImpressaoPedidoVenda;
