import React from 'react';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { ButtonsContainer, SelectContainer, ViewContainer } from './styles';

import api from '~/services/api';
import { toast } from 'react-toastify';

import { useImpressao } from '~/components/Impressao/ImpressaoContext';
import { CreatableSelect } from '@atlaskit/select';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CancelButton, ClearButton, ConfirmButton } from '~/components/Buttons';

import { Campo, FormView, Option } from '~/components/Impressao/types';

const MySwal = withReactContent(Swal);

export const ViewComponent: React.FC = () => {
  const {
    changeSelected,
    changeSelectedView,
    isChangeView,
    handleIsChangeView,
    handleHasSelectedView,
    handleChangeFormView,
    isNewView,
    handleIsNewView,
    handleSaveView,
    selectedView,
    handleSelectedView,
    selectedOption,
    handleSelectedOption,
    views,
    optionsSelect,
    handleOptionsSelect,
    getViews,
    optionsAvailable,
    setOptionsAvailable,
    selected,
    optionsAvailableDefault,
  } = useImpressao();

  const onCreateView = (inputValue: string) => {
    const valueToUpper = inputValue.toUpperCase();
    handleChangeFormView({
      des_impressao: valueToUpper,
    });
    const option = {
      label: inputValue.toUpperCase(),
      value: inputValue.toUpperCase(),
    };
    handleOptionsSelect([...optionsSelect, option]);
    handleSelectedOption(option);
    handleHasSelectedView(true);
    handleIsNewView(true);
    handleIsChangeView(true);
  };

  const onDeleteView = async () => {
    try {
      MySwal.fire({
        title: '',
        text: 'Deseja Deletar a Visualização?',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        icon: 'warning',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (isNewView) {
            const options = optionsSelect.filter(
              (option) => selectedOption?.value !== option.value,
            );
            const optionConcat = optionsAvailable.concat(selected);

            optionConcat.sort((a, b) => {
              if (a.des_campo < b.des_campo) return -1;
              if (a.des_campo > b.des_campo) return 1;
              return 0;
            });

            setOptionsAvailable(optionConcat);

            handleOptionsSelect(options);
            handleSelectedOption(null);
            changeSelected([]);
            handleIsChangeView(false);
          } else {
            const res = await api.delete(
              `/impressao/view/${selectedView?.cod_impressao}`,
            );
            const { success, message } = res.data;
            if (!success) throw new Error(message);

            const options = optionsAvailable.concat(selected);

            options.sort((a, b) => {
              if (a.des_campo < b.des_campo) return -1;
              if (a.des_campo > b.des_campo) return 1;
              return 0;
            });

            setOptionsAvailable(options);
            handleSelectedOption(null);
            getViews();
            changeSelected([]);
            changeSelected([]);
            handleIsChangeView(false);
            handleHasSelectedView(false);
            toast.success(message);
          }
        }
      });
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  const confirmChange = (callback: (value: boolean) => void) => {
    MySwal.fire({
      title: '',
      text: 'Há Alterações Pendentes, Deseja Continuar sem Salvar?',
      showCancelButton: true,
      confirmButtonText: 'Salvar Alterações',
      cancelButtonText: 'Continuar',
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#28a745',
      allowOutsideClick: false,
      icon: 'warning',
    }).then(async (result) => {
      if (result.isDismissed) {
        return callback(false);
      }
      return callback(true);
    });
  };

  const handleChangeView = async (value: Option | null) => {
    if (value) {
      if (isChangeView) {
        await confirmChange(async (confirmed) => {
          if (confirmed) {
            await handleSaveView();
          }
        });
      }
      handleIsNewView(false);
      handleHasSelectedView(true);
      handleSelectedOption(value);
      const selectedsFromView = views.find(
        (view) => view.cod_impressao === value.value,
      );
      if (selectedsFromView) {
        handleSelectedView(selectedsFromView);
        if (selectedsFromView) {
          const newSelecteds = selectedsFromView.campos.map((item: Campo) => {
            let desCampo = '';

            const optionsAvailableFind = optionsAvailableDefault.find(
              (option) => Number(option.cod_campo) === item.cod_campo,
            );

            if (optionsAvailableFind) {
              desCampo = optionsAvailableFind.des_campo;
            }

            return {
              order: item.num_ordem,
              cod_campo: item.cod_campo,
              des_campo: desCampo,
            };
          });

          const filteredOptions = optionsAvailableDefault.filter(
            (option) =>
              !newSelecteds.some(
                (selected2: any) =>
                  Number(selected2.cod_campo) === Number(option.cod_campo),
              ),
          );

          setOptionsAvailable(filteredOptions);
          return changeSelectedView(newSelecteds);
        }
      }

      return;
    }
    handleHasSelectedView(false);
    return changeSelectedView([]);
  };

  const onClearView = async () => {
    if (isChangeView) {
      await confirmChange(async (confirm) => {
        if (confirm) {
          await handleSaveView();
        } else if (isNewView) {
          const options = optionsSelect.filter(
            (option) => selectedOption?.value !== option.value,
          );
          handleOptionsSelect(options);
        }
        setOptionsAvailable(optionsAvailableDefault);
        handleSelectedOption(null);
        changeSelected([]);
        handleIsChangeView(false);
        handleHasSelectedView(false);
      });
    } else {
      if (isNewView) {
        const options = optionsSelect.filter(
          (option) => selectedOption?.value !== option.value,
        );
        handleOptionsSelect(options);
      }

      setOptionsAvailable(optionsAvailableDefault);

      handleHasSelectedView(false);
      handleSelectedOption(null);
      changeSelected([]);
      handleIsChangeView(false);
      handleChangeFormView({} as FormView);
    }
  };

  return (
    <ViewContainer>
      <SelectContainer>
        <label htmlFor="view-select" className="mb-1">
          Visualização
        </label>
        <CreatableSelect
          formatCreateLabel={(userInput: string) =>
            `TOQUE AQUI para criar a visualização '${userInput.toUpperCase()}'`
          }
          className="view-select"
          id="view-select"
          placeholder="Digite um nome para Cadastro ou Selecione uma Visualização"
          noOptionsMessage={() => 'Nenhum Registro Encontrado'}
          value={selectedOption}
          options={optionsSelect}
          onChange={handleChangeView}
          onCreateOption={onCreateView}
          inLine
        />
      </SelectContainer>

      <ButtonsContainer>
        <ConfirmButton onClick={handleSaveView} disabled={!isChangeView}>
          Salvar
        </ConfirmButton>
        <ClearButton
          onClick={onClearView}
          disabled={!selectedOption}
          style={{
            marginLeft: '0px',
          }}
        >
          Limpar
        </ClearButton>
        <CancelButton onClick={onDeleteView} disabled={!selectedOption}>
          Excluir
        </CancelButton>
      </ButtonsContainer>
    </ViewContainer>
  );
};
