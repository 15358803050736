import React from 'react';

import 'react-dual-listbox/lib/react-dual-listbox.css';

import { DualListBox } from '~/components/DualListBox';

import { useImpressao } from '~/components/Impressao/ImpressaoContext';

export const DualListComponent: React.FC = () => {
  const { optionsAvailable, selected, changeSelected, setOptionsAvailable } =
    useImpressao();

  const handleChange = (items: any) => {
    changeSelected(items);
  };

  return (
    <>
      <DualListBox
        optionAvailable={optionsAvailable}
        setOptionAvailable={setOptionsAvailable}
        optionSelected={selected}
        setOptionSelected={(e) => {
          handleChange(e);
        }}
      />
    </>
  );
};
