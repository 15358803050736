import React, { useEffect } from 'react';

import {
  DualListComponent,
  ActionsComponent,
  ViewComponent,
} from './components';

import { CloseButton, Container, LoaderContainer } from './styles';

import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';

import { useImpressao } from './ImpressaoContext';
import { AiOutlineClose } from 'react-icons/ai';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CircularProgress } from '@material-ui/core';

interface IImpressao {
  show: boolean;
  codTela: number;
  handleShow: (show: boolean) => void;
  filters: any;
}

const MySwal = withReactContent(Swal);

const Impressao: React.FC<IImpressao> = ({
  show,
  codTela,
  handleShow,
  filters,
}: IImpressao) => {
  const {
    handleCodTela,
    handleFilters,
    isChangeView,
    handleSaveView,
    isLoading,
  } = useImpressao();

  useEffect(() => {
    handleFilters(filters);
  }, [filters, handleFilters]);

  useEffect(() => {
    handleCodTela(codTela);
  }, [codTela, handleCodTela]);

  const handleCloseModal = () => {
    if (isChangeView) {
      MySwal.fire({
        title: '',
        text: 'Há alterações Pendentes, Deseja Continuar sem Salvar?',
        showCancelButton: true,
        confirmButtonText: 'Salvar Alterações',
        cancelButtonText: 'Continuar',
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#28a745',
        allowOutsideClick: false,
        icon: 'warning',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await handleSaveView();
          return handleShow(false);
        }
        return handleShow(false);
      });
    } else {
      handleShow(false);
    }
  };

  return (
    <>
      {show && (
        <Modal width="x-large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Impressão/Relatório</ModalTitle>
            <CloseButton onClick={handleCloseModal}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <LoaderContainer>
                <CircularProgress size={36} style={{ color: '#57069E' }} />
              </LoaderContainer>
            ) : (
              <Container>
                <ViewComponent />
                <DualListComponent />
                <ActionsComponent />
              </Container>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Impressao;
