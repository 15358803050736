import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  display: block;

  .react-dual-listbox {
    height: 300px;
  }

  .rdl-move {
    background-color: #22115a;
    &:hover {
      background-color: #53418d;
      transition: 0.2s;
    }
    &:active {
      background-color: #53418d;
    }
    &:focus {
      background-color: #53418d;
    }
    svg {
      color: #fff;
    }
  }

  .rdl-control > option {
    color: green;
  }

  .rdl-control {
    border-color: #2d136c;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;

export const Loader = styled.div`
  display: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
`;
