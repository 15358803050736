import React from 'react';
import Impressao from '~/components/Impressao';
import { ImpressaoContextProvider } from './ImpressaoContext';

interface IImpressao {
  show: boolean;
  codTela: number;
  handleShow: (show: boolean) => void;
  filters: any;
}

const ImpressaoContent: React.FC<IImpressao> = ({
  show,
  codTela,
  handleShow,
  filters,
}: IImpressao) => {
  return (
    <ImpressaoContextProvider>
      <Impressao
        show={show}
        codTela={codTela}
        handleShow={handleShow}
        filters={filters}
      />
    </ImpressaoContextProvider>
  );
};
export default ImpressaoContent;
